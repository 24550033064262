import { default as _91_46_46_46slug_93mmnngZ98qDMeta } from "/builds/allard-pierson/allard-pierson-frontend/pages/[...slug].vue?macro=true";
import { default as indexG3eYmZuWI1Meta } from "/builds/allard-pierson/allard-pierson-frontend/pages/index.vue?macro=true";
export default [
  {
    name: "slug___nl",
    path: "/:slug(.*)*",
    component: () => import("/builds/allard-pierson/allard-pierson-frontend/pages/[...slug].vue")
  },
  {
    name: "slug___en",
    path: "/en/:slug(.*)*",
    component: () => import("/builds/allard-pierson/allard-pierson-frontend/pages/[...slug].vue")
  },
  {
    name: "index___nl",
    path: "/",
    component: () => import("/builds/allard-pierson/allard-pierson-frontend/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/builds/allard-pierson/allard-pierson-frontend/pages/index.vue")
  }
]