import revive_payload_client_jIT7YtFfXB from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_xvorstj7dkpanktzug6q3t2hxi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_lL1CyFCJ9e from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_xvorstj7dkpanktzug6q3t2hxi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_vfeqrf1RuP from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_xvorstj7dkpanktzug6q3t2hxi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_EbDUzitino from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_xvorstj7dkpanktzug6q3t2hxi/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_wYSSx36EQ7 from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_xvorstj7dkpanktzug6q3t2hxi/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_CxOrGeOFig from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_xvorstj7dkpanktzug6q3t2hxi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_gHavscJwpN from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_xvorstj7dkpanktzug6q3t2hxi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import cross_origin_prefetch_client_dfZ1uxFdav from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_xvorstj7dkpanktzug6q3t2hxi/node_modules/nuxt/dist/app/plugins/cross-origin-prefetch.client.js";
import plugin_vue3_3iZpkJM8Hc from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typescript@5.6.2_vue@3.5.10_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/allard-pierson/allard-pierson-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_E2FOrd47rg from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_xvorstj7dkpanktzug6q3t2hxi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_PDmVvsaoWJ from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.10_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_hYpzIPiT9I from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.10_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_CrgjjL2gkQ from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/@digitalnatives+graphql-client@4.1.1_graphql@16.9.0_magicast@0.3.5_nuxt@3.13.2_@parcel+watche_luopngrixp3j5lhgxs2h6j4kyu/node_modules/@digitalnatives/graphql-client/dist/runtime/plugin.mjs";
import plugin_0Zrilfsm15 from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import nuxt_plugin_kHDoQexI91 from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import gtm_cXsUZobmSF from "/builds/allard-pierson/allard-pierson-frontend/plugins/gtm.ts";
export default [
  revive_payload_client_jIT7YtFfXB,
  unhead_lL1CyFCJ9e,
  router_vfeqrf1RuP,
  payload_client_EbDUzitino,
  navigation_repaint_client_wYSSx36EQ7,
  check_outdated_build_client_CxOrGeOFig,
  chunk_reload_client_gHavscJwpN,
  cross_origin_prefetch_client_dfZ1uxFdav,
  plugin_vue3_3iZpkJM8Hc,
  components_plugin_KR1HBZs4kY,
  prefetch_client_E2FOrd47rg,
  switch_locale_path_ssr_PDmVvsaoWJ,
  i18n_hYpzIPiT9I,
  plugin_CrgjjL2gkQ,
  plugin_0Zrilfsm15,
  nuxt_plugin_kHDoQexI91,
  gtm_cXsUZobmSF
]